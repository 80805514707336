import { Center, HStack, Img, Text, VStack } from "@chakra-ui/react"
import { ContentProps } from "../../../services/ContentService"
import { FiEye, FiMoreHorizontal, FiThumbsUp } from "react-icons/fi"
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useScreenSize } from "../../../providers/ScreenSizeProvider";

interface ContentCardProps {
    content: ContentProps;
}

export const ContentCard = ({ content }: ContentCardProps) => {
    const navigate = useNavigate()
    const { isMobile } = useScreenSize()

    const view = () => {
        navigate(`/contents/${content.code}`)
    }

    return (
        <VStack onClick={view} cursor={'pointer'} pos={'relative'} spacing={'8px'} align={'start'} w={isMobile ? '180px' : '212px'} h={'310px'}>
                <HStack overflow={'hidden'} borderTopRightRadius={'8px'} spacing={0} pos={'absolute'} right={0}>
                    {content.flag_advisory === 'MATURE' &&
                        <Center px={'8px'} h={'22px'} bg={'red.500'}>
                            <Text fontWeight={700} fontSize={'xs'} color={'white'}>18+</Text>
                        </Center>
                    }
                    <Center px={'8px'} h={'22px'} bg={'green.400'}>
                        <Text fontWeight={700} fontSize={'xs'} color={'black'} textTransform={'capitalize'}>
                            {content.type === 'SINGLE' && 'Single Titled' || content.type.toLowerCase()}
                        </Text>
                    </Center>
                </HStack>
                <Img objectFit={'cover'} src={content.thumbnail_url} w={'full'} h={'255px'} borderRadius={'8px'} />
                {/* <Center w={'full'} h={'255px'} borderRadius={'8px'} overflow={'hidden'} bg={'#000'}>
                    <Img src={content.thumbnail_url} />
                </Center> */}
                <VStack align={'start'} w={'full'} spacing={0}>
                    <Text fontWeight={600}>{content.title}</Text>
                    <HStack w={'full'} justify={'space-between'}>
                        <Text w={'90px'} whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'} fontSize={'xs'}>
                            {content.owner.firstname} {content.owner.lastname}
                        </Text>
                        <HStack align={'center'}>
                            <HStack align={'center'} spacing={'4px'}>
                                <FiEye size={'12px'} />
                                <Text fontSize={'xs'}>0</Text>
                            </HStack>
                            <HStack align={'center'} spacing={'4px'}>
                                <FiThumbsUp size={'12px'} />
                                <Text fontSize={'xs'}>0</Text>
                            </HStack>
                        </HStack>
                    </HStack>
                </VStack>
            </VStack>
    )
}