import { Box, Button, Center, CenterProps, HStack, Img, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react"
import { MainTemplate } from "../../../templates/MainTemplate"
import { ContentStatsCard } from "../components/ContentStatsCard"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { ContentProps, Get } from "../../../services/ContentService"
import { FiEdit, FiPlus } from "react-icons/fi"
import { ContentDetailsCard } from "../../contents/components/ContentDetailsCard"


export const ViewContentPage = () => {
    const [content, setContent] = useState<ContentProps>()
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            if (id) {
                const result = await Get(id)
                setContent(() => result)
            }
        })()
    }, [id])

    return (
        <MainTemplate>
            <VStack w={'full'}>
                {content &&
                    <VStack w={'full'} maxW={'1340px'} spacing={'32px'} align={'start'}>
                        <HStack w={'full'} justify={'space-between'}>
                            <HStack spacing={'24px'} align={'start'}>
                                <Text fontSize={'24px'} fontWeight={600}>{content.title}</Text>
                                <Text fontSize={'24px'} fontWeight={600}>{ content.is_paid ? `USD ${content.price}` : 'Free'}</Text>
                            </HStack>
                            <HStack>
                                <Button onClick={()=> navigate(`/contents/save/${id}`)} color={'green.400'} leftIcon={<FiEdit />}>
                                    Edit
                                </Button>
                                { content.type==='SERIES' &&
                                    <Button onClick={()=> navigate(`/contents/upload/${id}`)}  color={'green.400'} leftIcon={<FiPlus />}>
                                        Add Issue
                                    </Button>
                                }
                            </HStack>
                            
                        </HStack>
                        <HStack w={'full'} spacing={'20px'}>
                            <ContentStatsCard type="USER_ENGAGEMENT" value={300} stats={[
                                { label: 'Likes', value: 100 },
                                { label: 'Views', value: 100 },
                                { label: 'Comments', value: 100 }
                            ]}
                            />
                            <ContentStatsCard type="SALES" value={60} stats={[
                                { label: 'Comics', value: 20 },
                                { label: 'Merchandise', value: 40 }
                            ]} />
                            <ContentStatsCard type="EARNINGS" value={100} stats={[
                                { label: 'Comics', value: 20 },
                                { label: 'Merchandise', value: 40 },
                                { label: 'Others', value: 40 }
                            ]} />
                        </HStack>
                        <HStack align={'start'} spacing={'20px'} w={'full'}>
                            <ContentDetailsCard flexGrow={1} data={content} />
                            <Img w={'500px'} 
                                h={'500px'} 
                                cursor={'pointer'}  
                                src={content.thumbnail_url} 
                                objectFit={'cover'}
                                onClick={()=> navigate(`/contents/read/${content.id}`)} 
                            />
                        </HStack>
                    </VStack>
                }
            </VStack>
        </MainTemplate>
    )
}