import { Button, HStack, IconButton, Text, useToast, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { FiBookmark, FiChevronLeft, FiChevronRight, FiEye } from "react-icons/fi"
import { useNavigate, useParams } from "react-router-dom"
import { findWhere } from "underscore"
import { Select as NovaSelect } from '../../../components/Select'
import { ContentProps, GetDraft, Save } from "../../../services/ContentService"
import { MainTemplate } from "../../../templates/MainTemplate"
import { SaveProgressBar } from "../components/SaveProgressBar"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"

export enum AudienceType {
    ALL = 'ALL',
    KIDS = 'KIDS',
    TEENS = 'TEENS',
    ADULTS = 'ADULTS'
}

enum FlagAdvisoryType {
    MATURE = 'MATURE',
    NOT_MATURE = 'NOT_MATURE'
}

interface ReaderSettingProps {
    audience_type: AudienceType;
    flag_advisory: FlagAdvisoryType;
    is_download_available: boolean;
}

export const SaveReaderSettingsPage = () => {
    const [content, setContent] = useState<ContentProps>()
    const [is_saving, setIsSaving] = useState<boolean>()
    const [reader_settings, setReaderSettings] = useState<ReaderSettingProps>({
        audience_type: AudienceType.ALL,
        flag_advisory: FlagAdvisoryType.NOT_MATURE,
        is_download_available: true
    })
    const { isMobile } = useScreenSize()
    const toast = useToast()
    const { id } = useParams()
    const navigate = useNavigate()
    const audience_listing = [
        {
            label: 'Everyone',
            value: AudienceType.ALL
        },
        {
            label: 'Kids (3 - 12)',
            value: AudienceType.KIDS
        },
        {
            label: 'Teenagers (13 - 17)',
            value: AudienceType.TEENS
        },
        {
            label: 'Adults (18 - above)',
            value: AudienceType.ADULTS
        }
    ]

    const flag_advisory_options = [
        {
            label: 'Mature',
            subLabel: 'Your content contains graphic depiction of violence, sexuality, strong language, and/or other mature themes',
            value: FlagAdvisoryType.MATURE
        },
        {
            label: 'Not mature',
            subLabel: 'Your content is appropriate for all audience',
            value: FlagAdvisoryType.NOT_MATURE
        }
    ]

    const available_download_options = [
        {
            label: 'Yes',
            value: true
        },
        {
            label: 'No',
            value: false
        }
    ]

    useEffect(() => {
        (async () => {
            const result = await GetDraft(parseInt(id as string))
            setContent(() => result)
        })()
    }, [])

    const isValid = () => {
        return (reader_settings.audience_type && reader_settings.flag_advisory) ? true : false
    }

    const save = async () => {
        if(content){
            const { id } = content
            setIsSaving(() => true)
            try {
                const data = {
                    id,
                    ...reader_settings,
                    submit_for_review: true
                }
                const result = await Save(data as ContentProps)
                navigate(`/contents/success`)
            } catch (e) {
                console.log(e, 'error')
            }
            setIsSaving(() => false)
        }
    }

    const onSaveDraft = async () => {
        setIsSaving(() => true)
        try {
            const data = {
                ...content,
                ...reader_settings,
                submit_for_review: true
            }
            await Save(data as ContentProps)
            toast({
                description: `Changes saved as draft`,
                duration: 2000,
                status: 'success'
            })
        } catch (e) {
            console.log(e, 'error')
        }
        setIsSaving(() => false)
    }

    return (
        <MainTemplate>
            <VStack w={'full'}>
                <VStack w={'full'} maxW={'800px'} spacing={'32px'}>
                    <SaveProgressBar title={'Readers settings'} value={3}>
                        <HStack maxW={isMobile ? 'inherit' : '260px'} w={'full'} spacing={'16px'}>
                            <Button flexGrow={1} isDisabled={!isValid()} onClick={() => navigate(`/contents/${id}`)} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'} leftIcon={<FiEye />}>
                                Preview
                            </Button>
                            <Button flexGrow={1} isDisabled={!isValid()} onClick={onSaveDraft} variant={'outline'} px={'24px'} leftIcon={<FiBookmark />}>
                                Save
                            </Button>
                        </HStack>
                    </SaveProgressBar>
                    <HStack align={'start'}
                        w={'full'}
                        minH={'281px'}
                        bg={'#262333'}
                        borderRadius={'16px'}
                        p={isMobile ? '24px' : '32px'}
                        spacing={'20px'}>
                        <VStack w={'full'} spacing={'24px'} align={'start'}>
                            <VStack w={'full'} align={'start'}>
                                <Text>Audience</Text>
                                <NovaSelect value={findWhere(audience_listing, { value: reader_settings.audience_type })}
                                    setValue={value => setReaderSettings({ ...reader_settings, audience_type: value.value })}
                                    offset={[450, 10]}
                                    options={audience_listing}
                                    placeholder={'Select type of audience'} />
                            </VStack>
                            <VStack w={'full'} align={'start'}>
                                <Text>Advisory Flags</Text>
                                <NovaSelect width={'341px'} value={findWhere(flag_advisory_options, { value: reader_settings.flag_advisory })}
                                    setValue={value => setReaderSettings({ ...reader_settings, flag_advisory: value.value })}
                                    offset={[395, 10]}
                                    options={flag_advisory_options}
                                    placeholder={'Choose Advisory flag'} />
                            </VStack>
                            <VStack w={'full'} align={'start'}>
                                <Text>Available for download?</Text>
                                <NovaSelect value={findWhere(available_download_options, { value: reader_settings.is_download_available })}
                                    setValue={value => setReaderSettings({ ...reader_settings, is_download_available: value.value })}
                                    offset={[450, 10]}
                                    options={available_download_options}
                                    placeholder={'Choose option'} />
                            </VStack>
                        </VStack>
                    </HStack>
                    {isMobile &&
                        <HStack justify={'space-between'} w={'full'} spacing={'16px'}>
                            <IconButton aria-label={'Back'} onClick={() => navigate(`/contents/upload/${id}`)} variant={'outline'} px={'24px'} icon={<FiChevronLeft />} />
                            <IconButton aria-label={'Done'} isLoading={is_saving} onClick={save} isDisabled={!isValid()} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'} icon={<FiChevronRight />} />
                        </HStack>
                        ||
                        <HStack w={'full'} spacing={'16px'}>
                            <Button onClick={() => navigate(`/contents/upload/${id}`)} variant={'outline'} px={'24px'} leftIcon={<FiChevronLeft />}>
                                Back
                            </Button>
                            <Button isLoading={is_saving} loadingText={`Saving...`} onClick={save} isDisabled={!isValid()} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'} rightIcon={<FiChevronRight />}>
                                Proceed
                            </Button>
                        </HStack>
                    }

                </VStack>
            </VStack>
        </MainTemplate>
    )
}