import { Storage } from '@ionic/storage';
import qs from 'query-string';

export interface ContentFileProps {
    id?: string;
    filename?: string;
    size?: number;
    url: string;
}

export interface ContentAssetProps {
    id?: string;
    filename?: string;
    size?: number;
    url: string;
}

export interface SubContentProps {
    title?: string;
    description?: string;
    parent_id?: number;
    id?: number;
    file?: ContentFileProps;
    file_id?: number;
    asset?: ContentAssetProps;
    asset_id?: number;
    is_paid?: boolean;
    price?: number;
    thumbnail_url?: string;
    pages?: string[];
    created_at?: number;
    comments?: any[];
    is_bought?: boolean;
}

export interface ContentProps {
    code?: string;
    type: 'SINGLE' | 'SERIES' | string;
    thumbnail_url: string;
    title: string;
    description: string;
    genres: Array<any>;
    tags: Array<any>;
    is_paid: boolean;
    price?: number;
    audience_type?: string;
    flag_advisory?: string;
    is_download_available?: boolean;
    status?: 'UNDER_REVIEW' | 'PUBLISHED' | 'TEMPORARY_BANNED' | 'DRAFT'
    sub_contents?: Array<ContentProps>
    id?: number;
    parent_id?: number;
    created_at?: string;
    user_id?: number;
    owner?: any;
    submit_for_review?: boolean;
    pages?: string[];
}

export enum ContentCategoryProps {
    FEATURED = 'FEATURED',
    LATEST_RELEASE = 'LATEST_RELEASE',
    IN_THE_SPOTLIGHT = 'IN_THE_SPOTLIGHT',
    POPULAR = 'POPULAR'
}

export interface PublicListingFilterProps {
    categories?: ContentCategoryProps | string[];
    tags?: string[];
    type?: 'SINGLE' | 'SERIES' | string;
    genres?: string[]
    audience_types?: string[]
    prices?: string[],
    keyword?: string;
    user_id?: number;
}

const store = new Storage({
    name: process.env.REACT_APP_STORAGE_NAME
})



export const Save = async (content: ContentProps) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/contents/save`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(content),
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const SaveSubContent = async (content: SubContentProps) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/contents/save`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(content),
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const DeleteSubContent = async (sub_content_id: number) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/contents/subs/${sub_content_id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const Get = async (code: string, has_bought?: boolean) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    let url = `${process.env.REACT_APP_API_URL}/contents/${code}`
    if(has_bought){
        url = `${url}?has_bought=1`
    }
    const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const GetPageListing = async (id: number) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/contents/pages/${id}`, {
        method: "GET",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const GetFreePageListing = async (id: number) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/contents/pages/free/${id}`, {
        method: "GET",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const GetDraft = async (id: number) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/contents/drafts/${id}`, {
        method: "GET",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const GetPublicListing = async (params?: PublicListingFilterProps) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    let query_params = ''
    if (params) {
        query_params = qs.stringify(params)
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/contents/public/listing?${query_params}`, {
        method: "GET",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const GetListing = async () => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/contents/listing`, {
        method: "GET",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const AddComment = async (content_id: number, sub_content_id: number, message: string) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/contents/comments/add`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
            content_id,
            sub_content_id,
            message
        }),
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}
