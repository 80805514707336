import { Button, Center, HStack, IconButton, Input, Stack, StackProps, Text, VStack } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { FiBookmark, FiChevronLeft, FiChevronRight, FiEye, FiFileText, FiTrash } from "react-icons/fi"
import { useNavigate, useParams } from "react-router-dom"
import { any, findIndex, findWhere } from "underscore"
import { AssetReqProps, AssetType, GenerateUploadUrl, Save } from "../../../services/AssetService"
import { ContentFileProps, ContentProps, DeleteSubContent, GetDraft, SaveSubContent, SubContentProps } from "../../../services/ContentService"
import { MainTemplate } from "../../../templates/MainTemplate"
import { SaveProgressBar } from "../components/SaveProgressBar"
import { UpdateIssueInfoModal } from "../components/UpdateIssueInfoModal"
import { UploadFileBox } from "../components/UploadFileBox"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"
import { v4 as uuidv4 } from 'uuid'

const upload_box: StackProps = {
    py: '32px',
    cursor: 'pointer',
    w: 'full',
    bg: '#353444',
    borderRadius: '8px',
    justify: 'center',
    spacing: '10px'
}

const small_upload_box = {
    ...upload_box,
    width: '300px'
}

export const UploadFilesPage = () => {
    const [content, setContent] = useState<ContentProps>()
    const [sub_contents, setSubContents] = useState<SubContentProps[]>([])
    const [upload_files, setUploadFiles] = useState<ContentFileProps[]>([])
    const [is_changing_file, setIsChangingFile] = useState<boolean>()
    const { id } = useParams()
    const navigate = useNavigate()
    const change_file_ref = useRef<any>()
    const { isMobile } = useScreenSize()

    useEffect(() => {
        (async () => {
            const result = await GetDraft(parseInt(id as string))
            if (result.sub_contents && result.sub_contents.length > 0) {
                setSubContents(() => [...result.sub_contents])
            }
            setContent(() => result)
        })()
    }, [])

    useEffect(() => {
        if (upload_files.length > 0 && content) {
            (async () => {
                const _sub_contents = await Promise.all(upload_files.map((item: any) => {
                    return SaveSubContent({ asset_id: item.id, parent_id: content.id })
                }))
                for (let item of _sub_contents) {
                    const exist = findWhere(sub_contents, { id: item.id })
                    if (!exist) {
                        setSubContents(() => [...sub_contents, { ...item }])
                    }
                }
            })();
        }
    }, [upload_files])

    const formatSize = (size: number) => {
        if (size > 1000) {
            return `${Math.round(size / 1000)}MB`
        } else {
            return `${size}KB`
        }
    }

    const isValid = () => {
        return sub_contents.length > 0
    }

    const updateSubContent = async (sub_content: SubContentProps) => {
        if (content && sub_contents.length > 0) {
            try {
                await SaveSubContent({ ...sub_content })
                const index = findIndex(sub_contents, item => item.id === sub_content.id)
                if (index > -1) {
                    sub_contents[index] = { ...sub_content }
                    setSubContents(() => [...sub_contents])
                }
            } catch (e) {
                console.log(e, 'error')
            }
        }
    }

    const removeSubContent = async (sub_content: SubContentProps) => {
        try {
            await DeleteSubContent(sub_content.id as number)
            setSubContents(()=> [...sub_contents.filter((item: SubContentProps) => item.id !== sub_content.id)])
        } catch (e) {
            console.log(e, 'error')
        }
    }

    const preview = (sub_content: SubContentProps)=> {
        if(sub_content.asset){
            window.open(sub_content.asset.url)
        }
    }

    const onChangeFile = async (e: any)=> {
        let file: any = null
        if(e.target.files.length > 0){
            file = e.target.files[0]
        }

        if(file){
            setIsChangingFile(()=> true)
            const extension = file.name.split('.').pop()
            const filename = `${uuidv4()}.${extension}`
            const type = AssetType.CONTENT
            const { url, request_url } = await GenerateUploadUrl(type, filename, file)
            await fetch(request_url, {
                method: "PUT",
                headers: {
                    "x-amz-acl": "public-read"
                },
                body: file
            })
            const _file: AssetReqProps = { asset_type: type, url, filename, size: Math.round(file.size / 1024)}
            const result = await Save(_file)

            const sub_content = {...sub_contents[0]}
            sub_content.asset_id = result.id
            sub_content.asset = {...result}
            updateSubContent(sub_content)
            setIsChangingFile(()=> false)
        }
    }

    return (
        <MainTemplate>
            <VStack w={'full'}>
                <VStack w={'full'} maxW={'800px'} spacing={'32px'}>
                    <SaveProgressBar title={'Upload files'} value={2}>
                        <HStack maxW={isMobile ? 'inherit' : '260px'} w={'full'} spacing={'16px'}>
                                <Button flexGrow={1} isDisabled={!isValid()} onClick={()=> navigate(`/contents/${id}`)} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'} leftIcon={<FiEye />}>
                                    Preview
                                </Button>
                                <Button flexGrow={1} isDisabled={!isValid()} onClick={()=> navigate(`/account/contents`)} variant={'outline'} px={'24px'} leftIcon={<FiBookmark />}>
                                    Save
                                </Button>
                            </HStack>
                    </SaveProgressBar>
                    <HStack align={'start'} w={'full'} minH={'281px'} bg={'#262333'} borderRadius={'16px'} p={ isMobile ? '24px' : '32px'} spacing={'20px'}>
                        <VStack w={'full'} align={'start'} spacing={'16px'}>
                            <VStack w={'full'} align={'start'}>
                                <Text fontWeight={500}>Upload Content</Text>
                                <Text color={'gray.300'} fontSize={'small'}>Feel free to upload multiple episodes or chapters. Please ensure that you name them accordingly to distinguish between chapters or episodes.</Text>
                            </VStack>
                            {sub_contents.map((item: any, index: number) =>
                                <Stack cursor={'move'} 
                                    direction={isMobile? 'column': 'row'}
                                    key={index} 
                                    p={'16px'} 
                                    borderRadius={'8px'} 
                                    bg={'#353444'} 
                                    w={'full'} 
                                    spacing={'16px'}
                                    justify={'space-between'}>
                                    <HStack>
                                        <Center w={'40px'} h={'40px'} bg={'#3D3C4B'} borderRadius={'8px'}>
                                            <FiFileText size={'24px'} />
                                        </Center>
                                        <VStack spacing={0} align={'start'}>
                                            <Text fontSize={'14px'}>{item.asset.filename}</Text>
                                            <Text fontSize={'14px'}>{formatSize(item.asset.size)} of 10GB</Text>
                                        </VStack>
                                    </HStack>
                                    <HStack w={isMobile ? 'full': 'fit-content'} 
                                        justify={isMobile? 'space-between': 'start'}
                                        spacing={'12px'}>
                                        <HStack spacing={'12px'}>
                                            <Button color={'green.500'} onClick={()=>preview(item)}>Preview</Button>
                                            {sub_contents.length > 1 &&
                                                <UpdateIssueInfoModal mainContent={content as ContentProps} onSetSubContent={updateSubContent} subContent={item}>
                                                    <Button color={'green.500'}>Add Info</Button>
                                                </UpdateIssueInfoModal>
                                                ||
                                                <Button isLoading={is_changing_file} loadingText={'Uploading'} isDisabled={is_changing_file} onClick={()=> change_file_ref.current.click()} color={'green.500'}>Change File</Button>
                                            }
                                        </HStack>
                                        {sub_contents.length > 1 &&
                                            <IconButton onClick={() => removeSubContent(item)} icon={<FiTrash />} aria-label={'Delete'} />
                                        }
                                    </HStack>
                                </Stack>
                            )}
                            <UploadFileBox 
                                setFile={(file: ContentFileProps) => setUploadFiles([file])} 
                                w={sub_contents.length > 0 && !isMobile ? '300px' : 'full'} 
                                py={'32px'} 
                                type={AssetType.CONTENT} />
                        </VStack>
                    </HStack>
                    { isMobile &&
                        <HStack justify={'space-between'} w={'full'} spacing={'16px'}>
                            <IconButton aria-label={'back'} 
                                onClick={()=> navigate(`/contents/save/${id}`)} 
                                variant={'outline'} 
                                px={'24px'} 
                                icon={<FiChevronLeft />} />
                            <IconButton 
                                aria-label={'Next'}
                                onClick={() => navigate(`/contents/readers/${id}`)} 
                                isDisabled={!isValid()} 
                                px={'24px'} 
                                _hover={{ bg: 'green.300' }} 
                                bg={'green.400'} 
                                color={'black'} 
                                icon={<FiChevronRight />} />
                        </HStack>
                        ||
                        <HStack w={'full'} spacing={'16px'}>
                            <Button onClick={()=> navigate(`/contents/save/${id}`)} variant={'outline'} px={'24px'} leftIcon={<FiChevronLeft />}>
                                Back
                            </Button>
                            <Button onClick={() => navigate(`/contents/readers/${id}`)} isDisabled={!isValid()} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'} rightIcon={<FiChevronRight />}>
                                Proceed
                            </Button>
                        </HStack>
                    }
                </VStack>
            </VStack>
            <Input style={{display: 'none'}} onChange={(e: any)=> onChangeFile(e)} type="file" ref={change_file_ref} accept="application/pdf" />
        </MainTemplate>
    )
}