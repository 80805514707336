import { Box, Button, Grid, GridItem, HStack, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { FaChevronLeft } from "react-icons/fa"
import { FiUpload } from "react-icons/fi"
import { useNavigate } from "react-router-dom"
import { filter } from "underscore"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"
import { ContentProps, GetListing } from "../../../services/ContentService"
import { MainTemplate } from "../../../templates/MainTemplate"
import { ContentCard } from "../components/ContentCard"
import { EmptyUpload } from "../components/EmptyUpload"

export const ContentListingPage = () => {
    const [is_content_ready, setIsContentReady] = useState<boolean>()
    const [singles, setSingles] = useState<ContentProps[]>([])
    const [series, setSeries] = useState<ContentProps[]>([])
    const [under_reviews, setUnderReviews] = useState<ContentProps[]>([])
    const [drafts, setDrafts] = useState<ContentProps[]>([])
    const { isMobile } = useScreenSize()

    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            const result = await GetListing()
            setSingles(() => filter(result, item => item.type === 'SINGLE' && item.status === 'PUBLISHED'))
            setSeries(() => filter(result, item => item.type === 'SERIES' && item.status === 'PUBLISHED'))
            setUnderReviews(() => filter(result, item => item.status === 'UNDER_REVIEW'))
            setDrafts(() => filter(result, item => item.status === 'DRAFT'))
            setIsContentReady(() => true)
        })()
    }, [])

    return (
        <MainTemplate>
            <VStack w={'full'}>
                <VStack w={'full'} maxW={'1140px'} spacing={'32px'} align={'start'}>
                    <HStack p={'16px'}>
                        <Text fontSize={'24px'} fontWeight={600}>My Uploads</Text>
                    </HStack>
                    {/* <HStack bg={'#262333'} p={'16px'} w={'full'} justify={'space-between'}>
                        <HStack spacing={'8px'}>
                            <IconButton onClick={() => navigate(-1)} _focus={{ bg: 'transparent' }} size={'lg'} variant={'ghost'} aria-label={'Upload'} icon={<FaChevronLeft color={'#FFFFFF'} />} />
                            <Text fontSize={'24px'} fontWeight={600}>My Uploads</Text>
                        </HStack>
                        <IconButton onClick={() => navigate(`/contents/save`)} size={'lg'} colorScheme={'gray'} aria-label={'Upload'} icon={<FiUpload color={'#48BB78'} />} />
                    </HStack> */}
                    <Tabs pt={0} pb={'16px'} px={'16px'} colorScheme={'green'} w={'full'}>
                        <TabList className={'hidden-scrollbar'} pb={'2px'} overflowX={'auto'} position={'relative'}>
                            <Tab minW={'150px'} as={HStack} cursor={'pointer'} spacing={'6px'}>
                                <Text>
                                    Single Titled
                                </Text>
                                <Text color={'white'}>({singles.length})</Text>
                            </Tab>
                            <Tab as={HStack} cursor={'pointer'} spacing={'6px'}>
                                <Text>
                                    Series
                                </Text>
                                <Text color={'white'}>({series.length})</Text>
                            </Tab>
                            <Tab minW={'160px'} as={HStack} cursor={'pointer'} spacing={'6px'}>
                                <Text>Under Review</Text>
                                <Text color={'white'}>({under_reviews.length})</Text>
                            </Tab>
                            <Tab as={HStack} cursor={'pointer'} spacing={'6px'}>
                                <Text>
                                    Draft
                                </Text>
                                <Text color={'white'}>({drafts.length})</Text>
                            </Tab>
                            {!isMobile &&
                                <Box position={'absolute'} right={0} marginTop={'-8px'} marginRight={'8px'}>
                                    <Button color={'green.400'} onClick={() => navigate(`/contents/save`)}>Upload Comic</Button>
                                </Box>
                            }

                        </TabList>

                        <TabPanels>
                            <TabPanel minH={'230px'} align={'center'} justify={'center'} as={HStack}>
                                {is_content_ready && singles.length > 0 &&
                                    <Grid templateColumns={`repeat(${isMobile ? 2 : 6}, 1fr)`} gap={'16px'}>
                                        {singles.map((item: any, index: number) =>
                                            <GridItem key={index} w='100%'>
                                                <ContentCard content={item} />
                                            </GridItem>
                                        )}
                                    </Grid>
                                }

                                {is_content_ready && singles.length === 0 &&
                                    <EmptyUpload />
                                }
                            </TabPanel>
                            <TabPanel minH={'230px'} align={'center'} justify={'center'} as={HStack}>
                                {is_content_ready && series.length > 0 &&
                                    <Grid templateColumns={`repeat(${isMobile ? 2 : 6}, 1fr)`} gap={'16px'}>
                                        {series.map((item: any, index: number) =>
                                            <GridItem key={index} w='100%'>
                                                <ContentCard content={item} />
                                            </GridItem>
                                        )}
                                    </Grid>
                                }

                                {is_content_ready && series.length === 0 &&
                                    <EmptyUpload />
                                }
                            </TabPanel>
                            <TabPanel minH={'230px'} align={'center'} justify={'center'} as={HStack}>
                                {is_content_ready && under_reviews.length > 0 &&
                                    <Grid templateColumns={`repeat(${isMobile ? 2 : 6}, 1fr)`} gap={'16px'}>
                                        {under_reviews.map((item: any, index: number) =>
                                            <GridItem key={index} w='100%'>
                                                <ContentCard content={item} />
                                            </GridItem>
                                        )}
                                    </Grid>
                                }

                                {is_content_ready && under_reviews.length === 0 &&
                                    <EmptyUpload />
                                }
                            </TabPanel>
                            <TabPanel minH={'230px'} align={'center'} justify={'center'} as={HStack}>
                                {is_content_ready && drafts.length > 0 &&
                                    <Grid templateColumns={`repeat(${isMobile ? 2 : 6}, 1fr)`} gap={'16px'}>
                                        {drafts.map((item: any, index: number) =>
                                            <GridItem key={index} w='100%'>
                                                <ContentCard content={item} />
                                            </GridItem>
                                        )}
                                    </Grid>
                                }

                                {is_content_ready && drafts.length === 0 &&
                                    <EmptyUpload />
                                }
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </VStack>
            </VStack>
        </MainTemplate>
    )
}