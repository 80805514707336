import { Storage } from '@ionic/storage'

export interface RequestAddressProps {
    address: string;
    city: string;
    country: string;
    postal_code: string;
    state: string;
}

const store = new Storage({
    name: process.env.REACT_APP_STORAGE_NAME
})

export const GetCreditCardListing = async () => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/creditcards/listing`, {
        method: "GET",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const InitializeAccount = async (address: RequestAddressProps) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/accounts/initialize`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...address })
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const AddPaymentMethod = async (stripe_payment_method_id: string) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/accounts/add-payment-method`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ stripe_payment_method_id })
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const InitializePayoutAccount = async (country_code: string) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/payout/accounts/initialize`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country_code })
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const Initialize = async (amount: number) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/initialize`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount })
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const Confirm = async (content_id: number, sub_content_id: number, payment_method_id: string, payment_intent_id: string) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/confirm`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content_id, sub_content_id, payment_method_id, payment_intent_id })
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const RequestPayout = async (amount: number) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/payout/request`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount })
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const ConfirmPayoutAccount = async () => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/payments/payout/accounts/confirm`, {
        method: "GET",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}